import React from "react"
import { MdOpenInNew } from "react-icons/md"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Practitioner from "../../components/practitioner"
import FormLink from "../../components/formLink"

const data = [
	{
		name: "Professor Hans Nossent",
		type: "Lupus WA Chairman, Rheumatologist",
		address: "Sir Charles Gardiner, Rheumatology Clinic",
	},
	{
		name: "Dr Nicola Cook",
		type: "Rheumatologist",
		address: "SHENTON PARK",
		phone: "9380 9944",
	},
	{
		name: "Dr Gary Maloney",
		type: "Dermatologist",
		address: "Loftus Street Dermatology, Royal Street Dermatology",
		phone: "9228 0795, 9345 0695",
	},
	{
		name: "Dr Mark Reed",
		type: "Rheumatologist",
		address: "Hollywood Medical Centre, NEDLANDS",
		phone: "9389 6654",
	},
	{
		name: "Dr Andrew Lim",
		type: "Rheumatologist",
		address: "Hollywood Medical Centre, NEDLANDS",
		phone: "9389 6464",
	},
	{
		name: "Dr Aron Chakera",
		type: "Lupus WA Board Member, Nephrologist",
		address: "Hollywood Specialist Centre, NEDLANDS",
		phone: "9400 9726",
	},
	{
		name: "Dr Robert Langlands",
		type: "Rheumatologist",
		address: "Stafford St, MIDLAND",
		phone: "9274 5166",
	},
	{
		name: "Dr Robyn Yeo",
		type: "Nephrologist",
		address: "Suite 311, SJOG, Subiaco",
		phone: "9381 4310",
	},
]

const orgData = [
	{
		name:
			"Child and Adolescent Rheumatologists - Dr Senq Lee and Dr Kevin Murray",
		link: "http://www.paedrheumwa.com.au",
	},
	{
		name:
			"The Australasian Society of Clinical Immunology and Allergy (ASCIA) Western Australian members",
		link:
			"https://www.allergy.org.au/patients/locate-a-specialist/wa/tag/Nedlands",
	},
	{
		name:
			"The Australian Rheumatology Association (ARA) - contact details for ARA Rheumatologists who visit regional areas",
		link:
			"https://rheumatology.org.au/gps/visiting-rheumatologists-display-state.asp?State=WA",
	},
	{
		name:
			"(ARA) find a Rheumatologist search engine in any location Australia wide",
		link: "https://rheumatology.org.au/patients/find-a-rheumatologist.asp",
	},
]

const Specialists = () => (
	<Layout>
		<SEO title="Specialists specialising in lupus and/or autoimmune conditions" />
		<h1>Specialists</h1>
		<p>
			The following Specialists have been recommended by our Lupus WA community.
		</p>
		<p>
			<b>Please note:</b> You will require a referral from your GP to see an
			Immunologist, Rheumatologist or any other Specialist.
		</p>

		{data.map((practitioner, i) => (
			<Practitioner key={i} {...practitioner} />
		))}
		{orgData.map(({ name, link }, i) => (
			<a
				key={i}
				href={link}
				target="_blank"
				rel="noopener noreferrer"
				className="block flex rounded shadow p-4 my-8 text-purple-900 bg-purple-200 focus:outline-none focus:shadow-outline hover:bg-purple-300 font-bold"
			>
				<MdOpenInNew className="mr-6 flex-grow-0 flex-shrink-0 my-auto" />
				<span className="flex-grow">{name}</span>
			</a>
		))}

		<FormLink text="If you have any specialist suggestions or edits, " />
	</Layout>
)

export default Specialists
