import React from "react"
import PropTypes from "prop-types"
import { FaMapMarkerAlt } from "react-icons/fa"
import { MdPhone, MdPerson } from "react-icons/md"

const Practitioner = ({ name, address, phone, type }) => {
	return (
		<div className="rounded grid grid-cols-1 gap-1 shadow p-4 my-8 text-purple-900 bg-purple-200">
			<div className="flex font-bold">
				<MdPerson
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				<span className="flex-grow">
					{name}
					{type && ` (${type})`}
				</span>
			</div>
			<div className="flex">
				<FaMapMarkerAlt
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				{address}
			</div>
			{phone && (
				<a href={`tel:${phone}`} className="block flex">
					<MdPhone
						className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
						size="21px"
					/>
					<span className="underline">{phone}</span>
				</a>
			)}
		</div>
	)
}

Practitioner.propTypes = {
	name: PropTypes.string.isRequired,
	address: PropTypes.string.isRequired,
	phone: PropTypes.string,
	type: PropTypes.string,
}

Practitioner.defaultProps = {
	phone: null,
	type: null,
}

export default Practitioner
